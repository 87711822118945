.sidebar-header {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0;
}

.sidebar-header p {
  margin: 0;
}

.sidebar-header button {
  display: flex;
  align-items: center;
  margin-left: auto;
  border: none;
  background: 0;
  cursor: pointer;
  padding: 0.5rem;
  margin-right: -0.5rem;
}

.sidebar-header-icon:hover {
  color: #2962ff;
}

.icon-arrow-right {
  font-size: 1.5rem;
}
