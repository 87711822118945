.main {
  width: 100%;
  height: calc(100vh - 4rem);
  position: relative;
  z-index: 0;
}
.main-container {
  position: relative;
  width: 100%;
  height: inherit;
  padding: 1rem;
  perspective: 1000;
  backface-visibility: hidden;
}
.admin.sync .main-container {
  height: calc(100vh - 9.5rem);
}
