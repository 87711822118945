a.link,
a.link:hover {
  color: #383d4b;
  text-decoration: none;
}
a.link {
  border-bottom: 1px dashed #6c7284;
}
.main,
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media only screen and (min-width: 900px) {
  .main {
    flex: 1 1 calc(100% - 16.6666666667% * 2);
  }
}

@media only screen and (min-width: 1440px) {
  .main-container {
    padding: 1rem 0;
    max-width: calc(100% - 294px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .main-container.sidebar--right.is-open {
    transform: translate3d(-50%, 0, 0);
  }
}

/* Windows webkit scrollbars */

.windows::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms;
}
.windows::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
.windows::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  transition: background-color 200ms;
}
.windows::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
}

/* add vertical min-height & horizontal min-width */
.windows::-webkit-scrollbar-thumb:vertical {
  min-height: 10px;
}
.windows::-webkit-scrollbar-thumb:horizontal {
  min-width: 10px;
}
