html {
  height: 100%;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
html,
body,
#root {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#root {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
body {
  background-color: #f7f7f7;
  color: #000;
}

.icon {
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
