.sidebar {
  position: absolute;
  top: 4rem;
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
  min-width: 294px;
  height: calc(100vh - 4rem);
  background-color: #fff;
  transition: transform 300ms;
  z-index: 3;
  backface-visibility: hidden;
  perspective: 1000;
}

.sidebar.is-open {
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.05);
}

.sidebar.sidebar--right,
.sidebar.sidebar--left,
.sidebar.apps {
  visibility: hidden;
  transition: transform 300ms, visibility 0s linear 300ms;
}

.sidebar.has-message,
.sidebar.apps.has-message {
  top: 7.5rem;
}

.sidebar.sidebar--right.is-open,
.sidebar.sidebar--left.is-open,
.sidebar.apps.is-open {
  visibility: visible;
  transition: transform 300ms, visibility 0s linear 0s;
}
.sidebar.sidebar--right {
  transform: translate3d(0, 0, 0);
  left: 100%;
  width: 16.6666666667%;
  border-left: 1px solid #e9edf1;
}

.sidebar.sidebar--right.is-open,
.sidebar.apps.is-open {
  transform: translate3d(-100%, 0, 0);
}

.sidebar.sidebar--left {
  left: 0;
  border-right: 1px solid #e9edf1;
  transform: translate3d(-100%, 0, 0);
}

.sidebar.sidebar--left.is-open {
  transform: translate3d(0, 0, 0);
}

@media only screen and (min-width: 900px) {
  .sidebar.sidebar--left {
    position: relative;
    top: 0;
    max-width: 400px;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

/* Apps */

.sidebar.apps {
  position: absolute;
  top: 4rem;
  left: 100%;
  width: 16.6666666667%;
  z-index: 9999;
  border-right: none;
  background-color: #3c70ff;
  color: #fff;
  padding: 1.5rem;
  transition: transform 300ms;
  transform: translate3d(0, 0, 0);
  perspective: 1000;
  backface-visibility: hidden;
}

.sidebar.apps.is-open {
  transform: translate3d(-100%, 0, 0);
}

@media only screen and (min-width: 900px) {
  .sidebar.apps.is-open {
    transform: translate3d(0, 0, 0);
    transition: transform 300ms;
    perspective: 1000;
    backface-visibility: hidden;
  }
  .sidebar.apps {
    left: 0;
    transition: transform 300ms ease;
    perspective: 1000;
    backface-visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}
