.user-sidebar-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.user-sidebar-header::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 100%;
  border-bottom: 1px solid #e9edf1;
  content: '';
  transform: translate(-50%, 0);
}

.user-account {
  margin-left: 1rem;
}

.user-settings-container {
  padding: 1rem;
}

.user-settings {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-setting button {
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
  background-color: #fff;
  color: #383d4b;
  width: 100%;
  font-weight: normal;
  text-transform: none;
  font-size: 1rem;
}
