.navbar {
  position: relative;
  background: #fff;
  border-bottom: 1px solid #e9edf1;
  flex: 0 0 100%;
  height: 4rem;
  display: flex;
  z-index: 10000;
}

/* Brand */

.navbar-brand {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3c70ff;
  padding: 0 0 0 1rem;
}

@media only screen and (min-width: 900px) {
  .navbar-brand {
    flex: 0 0 16.6666666667%;
    min-width: 294px;
    max-width: 400px;
    /* border-right: 1px solid #e9edf1; */
  }
}

.navbar-brand::after {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  content: '';
}

.navbar-drawer-control {
  margin-left: auto;
}

/* Center */

.navbar-control-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.navbar-control {
  position: relative;
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  transition: transform 300ms;
  perspective: 1000;
  backface-visibility: hidden;
  padding: 1rem;
}

@media only screen and (min-width: 900px) {
  .navbar-control {
    flex: 1;
  }
}

@media only screen and (min-width: 1440px) {
  .navbar-control {
    max-width: calc(100% - 294px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    padding: 1rem 0;
  }
}

@media only screen and (min-width: 1920px) {
  .navbar-control {
    flex: 0 0 calc(8 / 12 * 100%);
  }
  .navbar-control.drawer-is-open {
    transform: translate3d(-65%, 0, 0);
  }
}

/* Right */

.navbar-drawer-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  order: 4;
}

@media only screen and (min-width: 1440px) {
  .navbar-drawer-control {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.navbar-drawer-control-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 4rem !important;
  height: 4rem;
  border: none;
  background: 0;
  font-size: 0.833em;
  cursor: pointer;
}

.navbar-drawer-control-button .icon {
  color: #4a4e5b;
  transition: all 300ms;
  perspective: 1000;
  backface-visibility: hidden;
}

.navbar-drawer-control-button.is-active .icon {
  color: #2962ff;
}

.navbar-drawer-control-button .icon-user {
  font-size: 2em;
}

.navbar-drawer-control-button .icon-notification {
  font-size: 2em;
}

/* Burger menu */

.navbar-sidebar-control {
  height: 100%;
  width: 4rem;
  flex: 0 0 4rem;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  line-height: normal;
  user-select: none;
  order: 0;
  border-right: 1px solid #e9edf1;
}

@media only screen and (min-width: 900px) {
  .navbar-sidebar-control {
    display: none;
  }
}
