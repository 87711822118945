.nav-item {
  width: 100%;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 2.5rem;
  color: #383d4b;
  text-decoration: none;
  transition: all 100ms;
  perspective: 1000;
  backface-visibility: hidden;
  user-select: none;
}

.nav-item:hover {
  color: #383d4b;
  background-color: #eaebee;
}

.nav-item.is-selected, .nav-item.is-selected p {
  position: relative;
  background-color: #f3f5f6;
  color: #2962ff;
}

.nav-item.is-selected::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0.25rem;
  height: 100%;
  background-color: #3c70ff;
  content: '';
}

.nav-item.is-selected:hover {
  background: #e9edf1;
}

.nav-item .icon {
  margin-right: 1rem;
  font-size: 1.3em;
}

.nav-item .count {
  margin-left: auto;
}
