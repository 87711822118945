.sign-in-main {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}
.sign-in {
  width: 100%;
  max-width: 24rem;
  background-color: #fff;
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  z-index: 9999;
}
.sign-in-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  background-color: #fff;
}
.askcody-logo {
  width: auto;
  height: 40px;
}
.bg-graphics {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.sign-in-footer {
  background-color: #f7f7f7;
  padding: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

.sign-in-footer p {
  display: inline;
}
.sign-in-footer p a {
  color: #001b52;
}