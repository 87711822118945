.sso-provider {
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  font-size: 0.833em;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #fff;
  border-top: 1px solid #f7f7f7;
  border-bottom: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  transition: background-color 100ms;
}
.sso-provider:focus  {
  background-color: rgba(188, 191, 201, 0.1);
}
.sso-provider:hover {
  background-color: rgba(188, 191, 201, 0.3);
}
.sso-provider:disabled {
  color: rgba(56, 61, 75, 0.4);
  cursor: not-allowed;
}
.sso-provider .icon {
  width: 24px;
  height: 24px;
  margin-left: 64px;
  margin-right: 16px;
}
